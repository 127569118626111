<template>
  <el-select
    ref="select"
    :value="layerOptionData.name"
    placeholder="默认为一级图层"
    clearable
    style="width: 100%"
    @clear="clear"
  >
    <el-option
      ref="option"
      class="option"
      :value="layerOptionData.id"
      :label="layerOptionData.name"
    >
      <el-tree
        ref="tree"
        class="tree"
        :data="data"
        :props="props"
        :default-expanded-keys="[value]"
        highlight-current
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
      >
        <div :class="['custom-tree-node']" slot-scope="{ node, data }">
          <!-- 图层 -->
          <template>
            <!-- 左侧箭头 -->
            <div
              class="custom-tree-node-toggle"
              @click.stop="node.expanded = !node.expanded"
            >
              <i
                class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                :class="node.expanded ? 'expanded' : ''"
              ></i>
            </div>
            <div class="custom-tree-node-text">
              <template>
                <!-- 名称 -->
                <span class="custom-tree-node-name">{{ data.name }}</span>
                <!-- 个数 -->
                <span class="custom-tree-node-num"
                  >（{{ data.child.length }}）</span
                >
              </template>
            </div>
          </template>
        </div>
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'TreeSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // v-model绑定
    value: {
      type: [String, Number],
      default: ''
    },
    // 树形的数据
    data: {
      type: Array,
      default: function () {
        return [];
      }
    },
    // 每个树节点用来作为唯一标识的属性
    nodeKey: {
      type: [String, Number],
      default: 'id'
    }
    // tree的props配置
    // props: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   }
    // }
  },
  data() {
    return {
      layerOptionData: {
        id: '',
        name: ''
      },
      props: {
        label: 'name',
        value: 'id',
        children: 'child'
      }
    };
  },
  methods: {
    handleNodeClick(data) {
      let label = this.props.label || 'name';
      this.layerOptionData.name = data[label];
      this.$emit('layerId', data);
    },
    clear() {
      this.layerOptionData.name = '';
      this.$emit('layerId', 0);
    }
  }
};
</script>

<style lang="less" scoped>
.option {
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: #fff;
}
// 自定义节点
.custom-tree-node {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5pxpx;
  color: #333;
  font-size: 16px;
  // 数据集
  &.dataset {
    color: #666;
  }
  // 数据类
  &.dataclass {
    font-size: 16px;
    color: #666;
    .custom-tree-node-toggle {
      margin-right: 12px;
      // 移除图标下方留白
      font-size: 0;
    }
  }
  // 左侧toggle
  &-toggle {
    margin-right: 8px;
    &-icon {
      float: left;
      transition: transform 200ms ease-in-out;
      &.expanded {
        transform: rotate(90deg);
        color: #507cff;
      }
    }
  }
  &-text {
    flex: 1;
    margin-right: 20px;
  }
  &-right {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
