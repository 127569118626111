<template>
  <!--图层管理-属性-样式 -->
  <div>
    <InfoItem label="来源：">
      <span slot="label"><span style="color: red">* </span>来源：</span>
      <el-select v-model="styleOrigin" @change="handleChangeSelect">
        <el-option
          v-for="option in styleOriginOptions"
          :key="option.value"
          :label="option.name"
          :value="option.value"
        />
      </el-select>
    </InfoItem>
    <!-- 自定义样式 -->
    <div v-show="styleOrigin == 2">
      <!-- 点样式 -->
      <InfoItem>
        <span slot="label"><span style="color: red">* </span>点样式：</span>
        <div class="default">
          <div class="default-point">
            <div class="default-point-box">
              <img
                v-if="iconStyle"
                :src="[
                  layerAttrDialogVisible.iconStyle
                    ? layerAttrDialogVisible.iconStyle.url
                    : ''
                ]"
              />
            </div>
          </div>
          <Button class="default-btn" @click="handleEdit('icon')"
            >编辑样式</Button
          >
        </div>
      </InfoItem>

      <!-- 线样式-->
      <InfoItem>
        <span slot="label"><span style="color: red">* </span>线样式：</span>
        <div class="default">
          <div class="default-line">
            <div class="default-line-box">
              <!-- 线段预览 -->
              <div class="style-preview">
                <div
                  class="style-preview-item"
                  v-for="(color, index) in lineFillColor"
                  :key="'1_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
            </div>
          </div>
          <Button class="default-btn" @click="handleEdit('line')"
            >编辑样式</Button
          >
        </div>
      </InfoItem>

      <!-- 多边形样式 -->
      <InfoItem>
        <span slot="label"><span style="color: red">* </span>多边形：</span>
        <div class="default">
          <div class="default-polygon-box">
            <div class="default-polygon-box-content">
              <div class="default-polygon-box-content-top">
                <div
                  class="item-width"
                  v-for="(color, index) in frameFillColor"
                  :key="'1_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
              <div class="default-polygon-box-content-box">
                <div class="default-polygon-box-content-left">
                  <div
                    v-show="index > 4"
                    class="item-height"
                    v-for="(color, index) in frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
                <!-- 填充内容 -->
                <div>
                  <div
                    class="default-polygon-box-content-color"
                    :style="{ background: fillColor }"
                  ></div>
                </div>
                <div class="default-polygon-box-content-right">
                  <div
                    v-show="index < 5"
                    class="item-height"
                    v-for="(color, index) in frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
              </div>
              <div class="default-polygon-box-content-bottom">
                <div
                  class="item-width"
                  v-show="index > 4"
                  v-for="(color, index) in frameFillColor"
                  :key="'1_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
                <div
                  class="item-width"
                  v-show="index < 5"
                  v-for="(color, index) in frameFillColor"
                  :key="'2_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
            </div>
          </div>
          <Button class="default-btn" @click="handleEdit('polygon')"
            >编辑样式</Button
          >
        </div>
      </InfoItem>
    </div>
    <!-- 样式库 -->
    <div v-show="styleOrigin === 1">
      <InfoItem>
        <span slot="label"><span style="color: red">* </span>文件：</span>
        <div class="default">
          <div class="default-style">
            <div class="default-style-box">
              <div v-if="styleResource">
                <el-tooltip
                  :content="layerAttrDialogVisible.styleResource.name"
                  :visible-arrow="false"
                  :enterable="false"
                  transition="none"
                  popper-class="atooltip1"
                  :disabled="
                    layerAttrDialogVisible.styleResource.name.length < 5
                  "
                >
                  <span class="default-style-box-text">
                    {{ layerAttrDialogVisible.styleResource.name }}</span
                  >
                </el-tooltip>
              </div>
            </div>
          </div>
          <Button class="default-btn" @click="handleEdit('resource')"
            >编辑样式</Button
          >
        </div>
      </InfoItem>
    </div>
  </div>
</template>

<script>
import $bus from 'p/utils';
import InfoItem from 'c/basic/InfoItem';
import Button from 'c/Button.vue';
import { ip } from 'p/urlSplice';
export default {
  name: 'LayerAttrStyle',
  components: {
    InfoItem,
    Button
  },
  data() {
    return {
      styleOrigin: 1,
      styleOriginOptions: [
        {
          name: '自定义样式',
          value: 2
        },
        {
          name: '样式库',
          value: 1
        }
      ],

      // 线段
      lineFillColor: [],
      // 多边形
      fillColor: '',
      frameFillColor: [],
      iconStyle: false,
      styleResource: false
    };
  },
  computed: {
    layerAttrDialogVisible: {
      get() {
        return this.$store.state.layerData.layerAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerAttrDialogVisible', val);
      }
    },
    dataclassAttrSytleEditDialog: {
      get() {
        return this.$store.state.data.dataclassAttrSytleEditDialog;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrSytleEditDialog', val);
      }
    }
  },
  mounted() {
    $bus.$off('getLayerAttrStyleData');
    $bus.$on('getLayerAttrStyleData', data => {
      this.dataProcessing(data);
    });
  },
  methods: {
    // 切换样式来源
    handleChangeSelect(val) {
      this.layerAttrDialogVisible.styleType = val;
      if (val == 1) {
        this.styleResource = this.layerAttrDialogVisible.styleResource
          ? true
          : false;
      } else {
        this.iconStyle = this.layerAttrDialogVisible.iconStyle ? true : false;
      }
    },
    // 编辑样式
    handleEdit(type) {
      const data = {
        status: true,
        type: type,
        text: '图层样式属性'
      };
      this.$store.commit('data/setDataclassAttrSytleEditDialog', data);
    },
    refreshData() {
      let num = 0;
      let num1 = 0;
      let { iconStyle, lineStyle, polygonStyle, styleResource } =
        this.layerAttrDialogVisible;
      if (iconStyle) {
        this.iconStyle = true;
        iconStyle.url = iconStyle?.url ? ip.urlSplice(iconStyle.url) : '';
      } else {
        this.iconStyle = false;
      }
      if (lineStyle) {
        const data = lineStyle;
        this.lineFillColor = [];
        for (let i = 0; i < data.fill.length; i++) {
          const element = data.fill.charAt(i);
          if (element == 1) {
            if (data.fillColor.length == 10) {
              this.lineFillColor.push(data.fillColor[i]);
            } else {
              this.lineFillColor.push(data.fillColor[num]);
              num = num + 1;
            }
          } else {
            this.lineFillColor.push('#FFFFFF00');
          }
        }
      }
      if (polygonStyle) {
        this.frameFillColor = [];
        const data = polygonStyle;
        this.fillColor = data.fillColor;
        for (let i = 0; i < data.frameFill.length; i++) {
          const element = data.frameFill.charAt(i);
          if (element == 1) {
            if (data.frameFillColor.length == 10) {
              this.frameFillColor.push(data.frameFillColor[i]);
            } else {
              this.frameFillColor.push(data.frameFillColor[num1]);
              num1 = num1 + 1;
            }
          } else {
            this.frameFillColor.push('#FFFFFF00');
          }
        }
      }
      this.styleResource = styleResource ? true : false;
    },
    // 数据处理
    dataProcessing(data) {
      switch (data.type) {
        case 'icon':
          this.layerAttrDialogVisible.pointStyleId = data.id;
          this.iconStyle = false;
          this.iconStyle = true;
          this.layerAttrDialogVisible.iconStyle = data;
          break;
        case 'line':
          this.layerAttrDialogVisible.lineStyleId = data.id;
          this.layerAttrDialogVisible.lineStyle = data;
          this.lineFillColor = data.fillColor;
          break;
        case 'polygon':
          this.layerAttrDialogVisible.polygonStyleId = data.id;
          this.layerAttrDialogVisible.polygonStyle = data;
          this.frameFillColor = data.frameFillColor;
          this.fillColor = data.fillColor;
          break;
        case 'resource':
          this.layerAttrDialogVisible.styleId = data.id;
          this.styleResource = false;
          this.styleResource = true;
          this.layerAttrDialogVisible.styleResource = data;
          break;
      }
    }
  },
  watch: {
    layerAttrDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.status) {
          this.styleOrigin = data.styleType;
          this.refreshData();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.default {
  height: 30px;
  display: flex;
  align-items: center;
  // 点编辑
  &-point {
    display: flex;
    &-box {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      img {
        margin: auto;
        max-width: 26px;
        max-height: 26px;
      }
    }
  }
  // 线编辑
  &-line {
    display: flex;
    &-box {
      width: 120px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      // 样式预览
      .style-preview {
        display: flex;
        width: 120px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin: 10px 5px;
        background: #fff;
        background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
        &-item {
          flex: 1;
          height: 8px;
        }
      }
    }
  }
  // 多边形编辑
  &-polygon {
    display: flex;
    &-box {
      margin-top: 48px;
      width: 120px;
      height: 80px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      // 样式预览
      &-content {
        margin: 5px;
        width: 110px;
        height: 70px;
        overflow: hidden;
        background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
        .item-width {
          display: flex;
          width: 12px;
          height: 7px;
        }
        .item-height {
          display: flex;
          width: 7px;
          height: 14px;
        }
        &-top {
          display: flex;
        }
        &-right {
          display: flex;
          flex-direction: column;
        }
        &-bottom {
          display: flex;
          flex-direction: row-reverse;
        }
        &-left {
          display: flex;
          flex-direction: column-reverse;
        }
        &-box {
          height: 56px;
          display: flex;
          justify-content: space-between;
        }
        &-color {
          width: 96px;
          height: 56px;
          z-index: 0;
        }
      }
    }
  }
  // 样式库
  &-style {
    display: flex;
    &-box {
      width: 120px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      overflow: hidden;
      &-text {
        user-select: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 16px;
        line-height: 30px;
        padding: 0 15px;
      }
    }
  }
  // 编辑按钮
  &-btn {
    margin-left: 10px;
    width: 82px;
    height: 24px;
    background: #507cff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }
}

// 选择框样式
/deep/.el-input {
  .el-icon-arrow-up:before {
    font-size: 20px;
    color: #507cff;
    font-weight: 500;
  }
  &__inner {
    border: 1px solid #507cff;
  }
}

// 样式库提示语
.reminder {
  width: 231px;
  height: 44px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f06012;
  line-height: 26px;
  text-align: center;
  margin: 40px auto 0;
}
</style>
