<template>
  <!-- 添加图层 -->
  <el-dialog
    title="新建图层"
    :visible.sync="addLayerDataDialogVisible"
    width="27.39583vw"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="6.25vw"
    >
      <el-form-item label="图层名称：" prop="layerName">
        <el-input
          v-model="form.layerName"
          placeholder="新建图层名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="上级图层：">
        <LayerTree
          v-model="form.layerId"
          :data="layerData"
          :props="props"
          @layerId="getLayerId"
        />
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="addLayerDataDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="handleConfirm('ruleForm')"
            >确定</Button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import LayerTree from 'c/workbench/LayerTree.vue';
export default {
  name: 'AddLayerDataDialog',
  components: {
    Button,
    LayerTree
  },
  data() {
    return {
      form: {
        layerName: '',
        layerId: ''
      },

      layerData: [],
      // 树状图配置
      props: {
        label: 'name',
        value: 'id',
        children: 'child'
      },
      rules: {
        layerName: [
          { required: true, message: '请输入图层名称', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    getTreeData() {
      const params = {
        workspaceId: this.$store.state.common.workspaceId
      };
      this.$service.layer.layerListTree(params).then(res => {
        if (res.data.status === 200) {
          this.layerData = res.data.data;
          // if (!this.layerId) {
          //   this.layerId = res.data.data[0].id;
          // }
        }
      });
    },
    getLayerId(data) {
      this.form.layerId = data.id;
    },

    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            name: this.form.layerName,
            parentId: this.form.layerId,
            workspaceId: this.$store.state.common.workspaceId
          };
          this.$service.layer.layerCreate(params).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.$emit('refreshLayerData');
              this.addLayerDataDialogVisible = false;
            }
          });
        }
      });
    }
  },
  computed: {
    addLayerDataDialogVisible: {
      get() {
        return this.$store.state.layerData.addLayerDataDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setAddLayerDataDialogVisible', val);
      }
    }
  },
  watch: {
    addLayerDataDialogVisible: {
      immediate: true,
      handler(data) {
        if (data) {
          this.getTreeData();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-cascader .el-input {
  width: 306px;
}
</style>
