<template>
  <!-- 更新服务 -->
  <el-dialog
    title="更新服务"
    :visible.sync="layerUpdateServiceDialogVisible"
    width="32.6vw"
    class="LayerUpdateServiceDialog"
  >
    <div class="LayerUpdateServiceDialog-title">
      <i class="icon icon-select"></i>
      更新服务
    </div>
    <div class="LayerUpdateServiceDialog-table">
      <el-table
        :data="listData"
        highlight-current-row
        style="width: 100%"
        @current-change="handleChange"
      >
        <el-table-column align="center" label="选择" width="70">
          <template slot-scope="scope">
            <el-radio
              v-model="checked"
              :label="scope.$index"
              class="radio"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="classify"
          label="分类"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="type"
          label="服务类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="addTime"
          label="发布时间"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
    </div>
    <div class="form-btn-group">
      <Button
        class="form-btn cancel"
        @click.native="layerUpdateServiceDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click.native="handleConfirm"
        >确定</Button
      >
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/Button.vue';
export default {
  name: 'LayerUpdateServiceDialog',
  components: { Button },
  prop: {
    //单选 使用哪个字段为唯一值 最好用id
    field: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      field: '',
      checked: null,
      checkedId: '',
      listData: [
        {
          id: 1,
          name: '1231212',
          classify: '水域',
          type: 'WMS',
          addTime: '2022-7-26 12:22:55'
        },
        {
          id: 2,
          name: '1231212',
          classify: '水域',
          type: 'WMS',
          addTime: '2022-7-26 12:22:55'
        },
        {
          id: 3,
          name: '1231212',
          classify: '水域',
          type: 'WMS',
          addTime: '2022-7-26 12:22:55'
        }
      ]
    };
  },
  computed: {
    layerUpdateServiceDialogVisible: {
      get() {
        return this.$store.state.layerData.layerUpdateServiceDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerUpdateServiceDialogVisible', val);
      }
    }
  },
  created() {},
  methods: {
    // 提交
    handleConfirm() {
      if (!this.checkedId) {
        return this.$message({
          message: '请选择服务',
          type: 'warning'
        });
      }
      this.layerUpdateServiceDialogVisible = false;
    },
    // 单选服务
    handleChange(data) {
      this.checkedId = data.id;
    }
  }
};
</script>
<style lang="less" scoped>
.LayerUpdateServiceDialog {
  /deep/.el-dialog__body {
    padding: 20px 30px;
  }

  &-title {
    width: 133px;
    height: 32px;
    background: #507cff;
    border-radius: 4px;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
  }
  &-table {
    margin-top: 10px;
    width: 566px;
    min-height: 177px;
    max-height: 477px;
    background: #ffffff;
    border: 1px solid #507cff;
    border-radius: 4px;
    overflow: hidden;
    /deep/.el-table {
      // 表格标题
      th.el-table__cell {
        font-size: 18px;
        background: #f8f9fd;
      }
      // 内容
      &__cell {
        line-height: 0;
        height: 44px;
        font-size: 16px;
        color: #333333;
        background-color: #ffffff;
        border: none;
        &.is-leaf {
          border: none;
        }
      }
      // 去除表格底部线条
      &::before {
        height: 0px;
      }
    }
  }
}

.radio /deep/ .el-radio__label {
  display: none;
}
</style>
