<template>
  <!-- 添加数据 -->
  <el-dialog :visible.sync="layerAddDataDialogVisible.status" width="18.3vw">
    <div class="layerTitle" slot="title">
      <el-tooltip
        :content="layerAddDataDialogVisible.name"
        :disabled="layerAddDataDialogVisible.name.length < 12"
        effect="light"
        placement="top"
      >
        <span>{{ layerAddDataDialogVisible.name }}</span>
      </el-tooltip>
    </div>
    <!-- 搜索框 -->
    <!-- <SearchBox v-model="keyword" @search="handleSearch" /> -->
    <div class="layerTitleTip">请选择需要添加的数据</div>
    <div class="layerData">
      <!-- 树状图 -->
      <el-tree
        ref="tree"
        :data="treeData"
        :props="props"
        :load="loadNode"
        lazy
        node-key="id"
        show-checkbox
        @check="handleChecked"
        class="treeData"
        :default-expanded-keys="[1]"
      >
        <!-- @node-click="handleClick" -->
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <!-- 根目录-->
          <template v-if="!data.name">
            <!-- 左侧箭头 -->
            <div class="custom-tree-node-toggle">
              <i
                class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                :class="node.expanded ? 'expanded' : ''"
              ></i>
            </div>
            <div class="custom-tree-node-text">
              <template>
                <!-- 名称 -->
                <span class="custom-tree-node-name">{{ data.typeName }}</span>
                <!-- 个数 -->
                <span class="custom-tree-node-num"
                  >（{{ data.datasets.length }}）</span
                >
              </template>
            </div>
          </template>
          <!-- 数据集 -->
          <template v-if="data.id && !data.datasetId && data.name" class="dada">
            <!-- 左侧箭头 -->
            <div class="custom-tree-node-toggle">
              <i
                class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                :class="node.expanded ? 'expanded' : ''"
                v-show="!node.isLeaf"
              ></i>
            </div>
            <div class="custom-tree-node-text">
              <!-- 数据集可编辑 -->
              <template v-if="data.name">
                <el-tooltip
                  :content="data.name"
                  :disabled="data.name.length < 15"
                  effect="light"
                  placement="top"
                >
                  <span class="custom-tree-node-name">{{ data.name }}</span>
                </el-tooltip>
              </template>
            </div>
          </template>
          <!-- 数据类 -->
          <template v-if="data.datasetId">
            <!-- 左侧图标 -->
            <div v-if="data.datasetId" class="custom-tree-node-toggle">
              <i :class="['icon', 'icon-' + data.dataType]"></i>
            </div>
            <div class="custom-tree-node-text">
              <!-- 名称 -->
              <el-tooltip
                :content="data.name"
                :disabled="data.name.length < 13"
                effect="light"
                placement="top"
              >
                <span class="custom-tree-node-name">{{ data.name }}</span>
              </el-tooltip>
            </div>
          </template>
        </div>
      </el-tree>
    </div>
    <div class="form-btn-group">
      <Button class="form-btn cancel" @click="layerAddDataDialogVisible = false"
        >取消</Button
      >
      <Button
        class="form-btn confirm"
        @click="confirm"
        :disabled="checkedNodes.length < 1"
        :style="{
          background: [checkedNodes.length < 1 ? '#9eabf6' : '#507cff']
        }"
        >确定</Button
      >
    </div>
  </el-dialog>
</template>

<script>
// import SearchBox from 'c/basic/SearchBox.vue';
export default {
  name: 'LayerAddDataDialog',
  components: {
    // SearchBox
  },
  data() {
    return {
      // 搜索关键字
      keyword: '',
      // 树状结构数据
      treeData: [
        { id: 1, type: 1, typeName: '要素数据目录', datasets: [] }
        // { id: 2, type: 2, typeName: '栅格数据目录', datasets: [] },
        // { id: 3, type: 3, typeName: '图表数据目录', datasets: [] },
        // { id: 4, type: 4, typeName: '视频数据目录', datasets: [] },
        // { id: 5, type: 5, typeName: '倾斜摄影模型数据', datasets: [] },
        // { id: 6, type: 6, typeName: '模型数据', datasets: [] }
      ],
      initialTreeData: [
        { id: 1, type: 1, typeName: '要素数据目录', datasets: [] }
        // { id: 2, type: 2, typeName: '栅格数据目录', datasets: [] },
        // { id: 3, type: 3, typeName: '图表数据目录', datasets: [] },
        // { id: 4, type: 4, typeName: '视频数据目录', datasets: [] },
        // { id: 5, type: 5, typeName: '倾斜摄影模型数据', datasets: [] },
        // { id: 6, type: 6, typeName: '模型数据', datasets: [] }
      ],
      // 树状图配置
      props: {
        label: 'name',
        value: 'id',
        isLeaf: 'leaf',
        type: 'type',
        children: 'datasets'
      },
      // 选中的复选框数据
      checkedNodes: []
    };
  },
  computed: {
    layerAddDataDialogVisible: {
      get() {
        return this.$store.state.layerData.layerAddDataDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerAddDataDialogVisible', val);
      }
    }
  },
  created() {
    this.getGroupData();
  },
  methods: {
    // 树形懒加载
    loadNode(node, resolve) {
      if (node.level > 2) return resolve([]);
      // 初始化 加载根目录数据
      if (node.level === 0) return resolve(this.initialTreeData);

      // 展开第一级节点 加载数据集
      if (node.level === 1) {
        return resolve(node.data.datasets);
      }
      // 展开第二级节点 加载数据类
      if (node.level === 2) return this.loadThirdlyNode(node, resolve);
    },
    // 加载数据类
    loadThirdlyNode(node, resolve) {
      const params = {
        datasetId: node.data.id
      };
      this.$service.data.featureClassGetByDataset(params).then(res => {
        if (res.data.status === 200) {
          res.data.data.map(item => {
            item.type = node.data.type;
          });
          resolve(res.data.data);
        }
      });
    },
    // 获取树形结构数据
    getGroupData() {
      const params = {
        workspaceId: this.$store.state.common.workspaceId
      };
      this.$service.data.datasetGetGroup(params).then(data => {
        if (data.data.status == 200) {
          let arr = this.initialTreeData;
          // 给目录赋初始值
          arr.forEach(item => {
            let obj = JSON.parse(JSON.stringify(item));
            this.treeData = this.treeData.map(item1 =>
              item1.type === obj.type ? obj : item1
            );
          });
          // 后端数据赋值
          data.data.data.forEach(item => {
            let obj = item;
            (obj.datasets[0].datasets = []),
              (this.treeData = this.treeData.map(item1 =>
                item1.type === obj.type ? obj : item1
              ));
          });
        }
      });
    },
    // 点击复选框
    handleChecked(data, checked) {
      // 点击数据集复选框
      if (data.typeName) {
        const targetNode = this.$refs.tree.getNode(data);
        if (!targetNode.expanded && !targetNode.loaded) {
          targetNode.loadData();
          targetNode.expanded = true;
          targetNode.checked = false;
        } else if (
          !targetNode.expanded &&
          targetNode.checked &&
          targetNode.loaded &&
          targetNode.childNodes[0]
        ) {
          targetNode.expanded = true;
        } else {
          if (!targetNode.childNodes[0]) {
            targetNode.checked = false;
            this.$message.warning('此数据集暂无数据');
          }
        }
      }
      if (checked.checkedNodes.length == 0) return (this.checkedNodes = []);
      let arr = checked.checkedNodes;
      this.checkedNodes = [];
      arr.map(item => {
        if (!item.typeName) {
          const obj = {
            dataId: item.id,
            type: item.type
          };
          this.checkedNodes.push(obj);
        }
      });
    },
    // 点击
    handleClick(data, node) {
      if (data.datasetId) {
        node.checked = !node.checked;
      }
    },
    // 提交表单
    confirm() {
      if (this.checkedNodes.length == 0) return this.$message('请选择数据');
      const params = {
        id: this.layerAddDataDialogVisible.id,
        dataJson: JSON.stringify(this.checkedNodes)
      };
      this.$service.layer.layerDataAdd(params).then(res => {
        if (res.data.status == 200) {
          this.$emit('refreshLayerDataShow', this.layerAddDataDialogVisible.id);
          this.$emit('partialRefresh');
          this.layerAddDataDialogVisible = false;
          this.$message.success(res.data.msg);
        }
      });
    }
  },
  watch: {
    layerAddDataDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.status) {
          this.getGroupData();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
/* 一级复选框隐藏 */
/deep/.el-tree > .el-tree-node > .el-tree-node__content .el-checkbox {
  display: none;
}

/deep/.el-dialog__body {
  padding: 20px 30px;
}
/deep/.el-tree-node__content {
  display: flex;
  align-items: center;
}
/deep/.el-checkbox__inner {
  user-select: none;
  margin: -12px -10px 0 8px !important;
}
.layerTitle {
  user-select: none;
  width: 290px !important;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  margin-top: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.layerTitleTip {
  user-select: none;
  margin: -15px 0 10px;
  color: #666;
}
.layerData {
  width: 292px;
  max-height: 550px;
  min-height: 295px;
  background: #ffffff;
  border: 1px solid #507cff;
  border-radius: 4px;
  overflow-x: auto;
}
/deep/.el-tree-node__loading-icon {
  position: absolute;
  margin-top: 1px !important;
  margin-left: -12px !important;
  display: flex;
}
// 自定义节点
.custom-tree-node {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  color: #333;
  font-size: 16px;
  // 左侧toggle
  &-toggle {
    margin-right: 3px;
    margin-top: 5px;
    &-icon {
      margin-top: -5px;
      float: left;
      transition: transform 200ms ease-in-out;
      &.expanded {
        transform: rotate(90deg);
        color: #507cff;
      }
    }
  }
  &-text {
    flex: 1;
    margin-right: 20px;
  }
}
</style>
