<template>
  <div>
    <Panel title="图层管理" @close="handleClose" v-loading="!workspaceId">
      <div class="layerManager">
        <div class="layerManager-header">
          <div class="layerManager-header-top">
            <!-- 左侧工作空间 -->
            <WorkSpaceSelect :value="workspaceId" />
            <!-- 右侧那妞 -->
            <div class="layerManager-header-btn-group">
              <!-- 添加图层 -->
              <list-tooltip content="添加图层">
                <button class="layerManager-header-btn" @click="addLayerData">
                  <i class="icon icon-dataAdd"></i>
                </button>
              </list-tooltip>
              <!-- toggle按钮 -->
              <list-tooltip :content="!toggle ? '收起目录' : '展开已加载数据'">
                <button class="layerManager-header-btn" @click="toggleTree">
                  <i
                    :class="[
                      'icon',
                      !toggle ? 'icon-tree-toggleUp' : 'icon-tree-toggleDown'
                    ]"
                  ></i>
                </button>
              </list-tooltip>
            </div>
          </div>

          <!-- 搜索框 -->
          <!-- <div class="layerManager-header-serach">
            <SearchBox v-model="keyword" @search="handleSearch" />
          </div> -->
        </div>
        <div class="layerManager-content">
          <!-- 树状图 -->
          <el-tree
            ref="tree"
            :data="treeData"
            :props="props"
            :load="loadNode"
            lazy
            node-key="id"
            :filter-node-method="filterNode"
            draggable
            @node-drop="handleDrop"
            :allow-drop="handleAllowDrop"
          >
            <!-- @node-drag-end="handleDragEnd" -->
            <div
              :class="'custom-tree-node'"
              slot-scope="{ node, data }"
              @contextmenu="handleContextmenu($event, data, node)"
            >
              <!-- 图层 -->
              <template v-if="!data.layerId">
                <!-- 左侧箭头 -->
                <div class="custom-tree-node-toggle">
                  <i
                    class="custom-tree-node-toggle-icon"
                    :class="layerDataFeaturesKey[data.id] ? 'box1' : 'box'"
                    v-if="data.child"
                    :key="layerDataFeaturesKey[data.id]"
                    @click.stop="handleChecked(data)"
                  ></i>
                  <i
                    class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                    :class="node.expanded ? 'expanded' : ''"
                    v-show="!node.isLeaf"
                  ></i>
                </div>
                <div class="custom-tree-node-text">
                  <!-- 图层可编辑 -->
                  <EditText
                    v-if="data.id == editTextId"
                    class="editText"
                    :value="data.name"
                    :id="data.id"
                    :maxlength="20"
                    @edit="text => handleDatasetNameEdit(text, data)"
                    @del="text => handleDatasetNameDel(text, data, node)"
                  >
                  </EditText>

                  <template v-else>
                    <!-- 名称 -->
                    <el-tooltip
                      :content="data.name"
                      placement="bottom"
                      :enterable="false"
                      transition="none"
                      :visible-arrow="false"
                      popper-class="atooltip1"
                    >
                      <span>{{ data.name }}</span>
                    </el-tooltip>
                  </template>
                </div>
                <!-- 右侧按钮 -->
                <div
                  class="custom-tree-node-right"
                  v-show="data.id !== editTextId"
                >
                  <i
                    class="icon icon-setting"
                    @click.stop="handleLayerProperty(data)"
                  ></i>
                  <!-- <i class="icon icon-check"></i> -->
                </div>
              </template>
              <!-- 数据类-->
              <template v-if="data.layerId">
                <div
                  class="custom-tree-node-toggle"
                  v-show="data.dataId + data.layerId !== editTextId"
                >
                  <i :class="['icon', 'icon-' + data.subType]"></i>
                </div>
                <div class="custom-tree-node-text">
                  <EditText
                    v-if="data.dataId + data.layerId == editTextId"
                    class="editText"
                    :value="data.name"
                    :id="data.dataId + data.layerId"
                    :maxlength="30"
                    @edit="text => handleDatasetNameEdit(text, data)"
                    @del="text => handleDatasetNameDel(text, data, node)"
                  >
                  </EditText>
                  <template v-else>
                    <!-- 名称 -->
                    <el-tooltip
                      :content="data.name"
                      placement="bottom"
                      :enterable="false"
                      transition="none"
                      :visible-arrow="false"
                      popper-class="atooltip1"
                    >
                      <span>{{ data.name }}</span>
                    </el-tooltip>
                  </template>
                </div>
                <!-- 右侧按钮 -->
                <div
                  class="custom-tree-node-right"
                  v-show="data.dataId + data.layerId !== editTextId"
                >
                  <!-- <i class="icon icon-check"></i> -->
                  <i
                    class="icon icon-attrSheet"
                    @click.stop="handleCategoryAttributeTable(data)"
                  ></i>
                  <i
                    class="icon icon-setting"
                    @click.stop="handleCategoryProperty(data, node)"
                  ></i>
                </div>
              </template>
            </div>
          </el-tree>
        </div>
      </div>
    </Panel>
    <!-- 添加图层 -->
    <AddLayerDataDialog
      v-if="addLayerDataDialogVisible"
      @refreshLayerData="getTreeData(workspaceId)"
    />
    <!-- 添加图层数据 -->
    <LayerAddDataDialog
      v-if="layerAddDataDialogVisible.status"
      @partialRefresh="partialRefresh"
      @refreshLayerDataShow="refreshLayerDataShow"
    />
    <!-- 更新服务 -->
    <LayerUpdateServiceDialog v-if="layerUpdateServiceDialogVisible" />
    <!-- 服务发布 -->
    <LayerPublishingServiceDialog v-if="layerPublishingServiceDialogVisible" />
    <!-- 图层属性 -->
    <LayerAttrDialog @refreshLayerData="getTreeData(workspaceId)" />
    <!-- 数据类属性 -->
    <LayerClassAttrDialog
      v-if="layerClassAttrDialogVisible.status"
      @partialRefresh="partialRefresh"
    />
    <!-- 数据类属性表 -->
    <DataclassAttrSheetDialog v-if="dataclassAttrSheetPanelVisible.status" />
    <!-- 数据类属性字段 -->
    <DataclassAttrFieldDialog v-if="dataclassAttrFieldDialogVisible" />
    <!-- 属性样式编辑窗口 -->
    <DataclassAttrSytleEditDialog />
  </div>
</template>

<script>
import $bus from 'p/utils';
import { mapState } from 'vuex';
import Panel from 'c/basic/Panel';
import EditText from 'c/basic/EditText';
// import SearchBox from 'c/basic/SearchBox';
import WorkSpaceSelect from 'c/workbench/WorkSpaceSelect';
import AddLayerDataDialog from './AddLayerDataDialog';
import LayerAddDataDialog from './LayerAddDataDialog';
import LayerUpdateServiceDialog from './LayerUpdateServiceDialog.vue';
import LayerPublishingServiceDialog from './LayerPublishingServiceDialog .vue';
import LayerAttrDialog from './LayerAttrDialog';
import LayerClassAttrDialog from './LayerClassAttrDialog';
import DataclassAttrSytleEditDialog from './../dataManager/DataclassAttrSytleEditDialog.vue';
import DataclassAttrSheetDialog from './../dataManager/DataclassAttrSheetDialog';
import DataclassAttrFieldDialog from './../dataManager/DataclassAttrFieldDialog';
// 引入图层右键菜单数据
import {
  layerDataRootContextMenuData,
  layerDataClassContextMenuData
} from './layerDataContextMenuData';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'LayerManager',
  components: {
    Panel,
    EditText,
    // SearchBox,
    WorkSpaceSelect,
    AddLayerDataDialog,
    LayerAddDataDialog,
    LayerUpdateServiceDialog,
    LayerPublishingServiceDialog,
    LayerAttrDialog,
    LayerClassAttrDialog,
    DataclassAttrSytleEditDialog,
    DataclassAttrSheetDialog,

    DataclassAttrFieldDialog,
    ListTooltip
  },
  data() {
    return {
      layerDataRootContextMenuData: layerDataRootContextMenuData(),
      layerDataClassContextMenuData: layerDataClassContextMenuData(),
      // 搜索关键字
      keyword: '',
      // 图层数据
      treeData: [],
      // 树状图配置
      props: {
        label: 'name',
        value: 'id',
        isLeaf: 'leaf',
        children: 'child',
        disabled: function (data) {
          return !data.child;
        }
      },
      // 树状天收起展开状态
      toggle: false,
      // 树形状态
      inventoryStatus: [],
      currentNode: null,
      rootNode: null
    };
  },
  computed: {
    layerDataFeaturesKey: {
      get() {
        return this.$store.state.layerData.layerDataFeaturesKey;
      },
      set(val) {
        this.$store.commit('layerData/setLayerDataFeaturesKey', val);
      }
    },
    ...mapState('layerData', [
      'addLayerDataDialogVisible',
      'layerAddDataDialogVisible',
      'layerUpdateServiceDialogVisible',
      'layerPublishingServiceDialogVisible',
      'layerClassAttrDialogVisible',
      'addLayerId',
      'layerManagerData'
    ]),
    ...mapState('common', ['workspaceId', 'delData', 'editTextId']),
    ...mapState('data', [
      'dataclassAttrSheetPanelVisible',
      'dataclassAttrFieldDialogVisible'
    ])
  },
  mounted() {
    if (this.workspaceId) {
      this.getTreeData(this.workspaceId);
    }
    $bus.$off('refreshLayerData');
    $bus.$off('refreshPartialLayer');

    $bus.$on('refreshLayerData', () => {
      this.getTreeData(this.workspaceId);
    });
    $bus.$on('refreshPartialLayer', () => {
      this.partialRefresh();
    });
  },
  destroyed() {
    $bus.$emit('quitPlot');
    this.$store.commit('layerData/quitLayerManager');
    this.$store.commit('data/setDataclassAttrSheetPanelVisible', false);
    this.$store.commit('common/setContextMenuVisible', false);
  },
  methods: {
    // 树形懒加载获取数据类
    loadNode(node, resolve) {
      this.rootNode = node;
      if (node.data.layerId) return resolve([]);
      if (node.level > 0) {
        // 编辑时当前行不能点击加载
        if (this.editTextId == node.data.id) return;
        this.$service.layer.layerDataList({ id: node.data.id }).then(res => {
          if (res.data.status === 200) {
            const data = node.data.child.concat(res.data.data);
            resolve(data);
          }
        });
      }
    },
    // 拖拉移动放置判断
    handleAllowDrop(draggingNode, dropNode) {
      if (dropNode.data.dataId) return false;
      return true;
    },
    // 拖拉移动结束-提交请求
    handleDrop(Node, parenNode, type) {
      if (type == 'before') {
        parenNode.data.id = parenNode.level == 1 ? '0' : parenNode.data.id;
      }
      const params = {
        id: Node.data.id,
        parentId: parenNode.data.id
      };
      this.$service.layer.layerMove(params).then(res => {
        if (res.data.status === 200) {
          this.$message.success(res.data.msg);
        }
      });
    },
    // 图层属性快捷键
    handleCategoryAttributeTable(data) {
      this.$store.commit('common/setContextClickData', data);
      const item = this.layerDataClassContextMenuData[3];
      item.fn && item.fn(this, item);
    },
    // 要素类属性表快捷键
    handleLayerProperty(data) {
      this.$store.commit('common/setContextClickData', data);
      const item = this.layerDataRootContextMenuData[9];
      item.fn && item.fn(this, item);
    },
    // 数据类属性快捷按钮
    handleCategoryProperty(data, node) {
      this.currentNode = node.parent;
      this.$store.commit('common/setContextClickData', data);
      const item = this.layerDataClassContextMenuData[4];
      item.fn && item.fn(this, item);
    },
    // 局部刷新方法
    partialRefresh() {
      const node = this.currentNode;
      // 新增或修改时,更新当前节
      node.loaded = false;
      node.loadData(); // 重新查询当前节点的所有子元素
      node.expanded = true;
    },
    // 刷新前保存树形结构展开状态
    stateOfTheTree() {
      this.inventoryStatus = [];
      if (this.$refs.tree.root) {
        this.$refs.tree.root.childNodes.forEach(item => {
          this.inventoryStatus.push(item.expanded);
        });
      }
    },
    // 刷新后恢复树形结构展开状态
    restoreTheTreeState() {
      this.inventoryStatus.forEach((item, index) => {
        if (this.$refs.tree.root) {
          this.$refs.tree.root.childNodes[index].expanded = item;
        }
      });
    },
    getTreeData(id) {
      // this.stateOfTheTree();
      const params = {
        workspaceId: id
      };
      this.$service.layer.layerListTree(params).then(res => {
        if (res.data.status === 200) {
          this.treeData = res.data.data;
          this.$store.commit('common/setEditTextId', '');
          // 保存树形图层数据
          this.$store.commit('layerData/setLayerTreeData', this.treeData);
          this.currentNode = null;
          // 恢复树形结构展开状态
          // if (this.inventoryStatus) {
          //   this.$nextTick(() => {
          //     this.restoreTheTreeState();
          //   });
          // }
        }
      });
    },
    // 关闭编辑数据集输入框
    handleDatasetNameDel(text, data, node) {
      if (data.id) {
        // 判断是新增还是编辑
        // 编辑
        if (data.id.length > 10) {
          // 还原名称
          node.data.name = text;
        } else {
          // 新增
          // 关闭时直接移除节点
          node.remove();
        }
      }
      this.$store.commit('layerData/setAddLayerId', '');
    },
    // 新增和重命名
    handleDatasetNameEdit(text, data) {
      if (!text) return this.$message('名称不能为空');
      // 图层
      if (data.id) {
        let params = {};
        let requestType = ''; //请求接口
        if (data.id < 1000000000) {
          // 新增数据集
          requestType = 'layerCreate';
          params = {
            name: text,
            parentId: this.currentNode.data.id,
            workspaceId: this.workspaceId
          };
        } else {
          // 重命名数据集
          requestType = 'layerModifyName';
          params = {
            id: data.id,
            name: text
          };
        }
        this.$service.layer[requestType](params).then(res => {
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$store.commit('common/setEditTextId', '');
            if (requestType == 'layerModifyName') {
              data.name = text;
            } else {
              this.getTreeData(this.workspaceId);
            }
            this.$store.state.layerData.addLayerId = false;
            this.partialRefresh();
          }
        });
      } else {
        const dataId = data.dataId;
        // 要素数据类
        this.$service.data
          .featureClassRename({ id: dataId, name: text })
          .then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.$store.commit('common/setEditTextId', '');
              this.dataClassRenamingTraversal(
                text,
                dataId,
                this.$refs.tree.root.childNodes
              );
            }
          });
      }
    },
    // 数据类重命名遍历
    dataClassRenamingTraversal(text, dataId, layers) {
      for (let i = 0; i < layers.length; i++) {
        const { data, childNodes } = layers[i];
        if (data.dataId) {
          if (data.dataId == dataId) {
            data.name = text;
          }
        }
        if (childNodes.length) {
          this.dataClassRenamingTraversal(text, dataId, childNodes);
        }
      }
    },
    // 点击复选框更换显示状态
    handleChecked(data) {
      if (this.layerDataRootContextMenuData[8].label != '加载中...') {
        const { icon, label, disabled } = this.layerDataFeaturesDataShow(data);
        this.layerDataRootContextMenuData[8].icon = icon;
        this.layerDataRootContextMenuData[8].label = label;
        this.layerDataRootContextMenuData[8].disabled = disabled;
      }
      this.$store.commit('common/setContextClickData', data);
      const item = this.layerDataRootContextMenuData[8];
      item.fn && item.fn(this, item);
    },
    refreshLayerDataShow(layerId) {
      if (this.layerDataFeaturesKey[layerId]) {
        if (this.layerDataRootContextMenuData[8].label != '加载中...') {
          this.layerDataRootContextMenuData[8].icon = 'showData';
          this.layerDataRootContextMenuData[8].label = '展示数据';
          this.layerDataRootContextMenuData[8].disabled = false;
        }
        const item = this.layerDataRootContextMenuData[8];
        item.fn && item.fn(this, item);
      }
    },
    // 要素类数据的展示状态
    layerDataFeaturesDataShow(data) {
      let showFeatures = this.layerDataFeaturesKey[data.id];
      let contextMenuData = {};
      if (showFeatures == true) {
        contextMenuData.icon = 'hideData';
        contextMenuData.label = '隐藏数据';
        contextMenuData.disabled = false;
      } else {
        contextMenuData.icon = 'showData';
        contextMenuData.label = '展示数据';
        contextMenuData.disabled = false;
      }
      return contextMenuData;
    },
    // 右键
    handleContextmenu(e, data, node) {
      // 内容输入框编辑时禁止右键操作
      if (data.id == this.editTextId || data.dataId == this.editTextId) return;
      if (data.dataId) {
        this.currentNode = node.parent;
      } else {
        this.currentNode = node;
      }
      this.$store.commit('layerData/setLayerTreeData', this.treeData);
      this.$store.commit('common/setContextClickData', data);
      let contextMenuData = '';
      if (!data.dataId) {
        if (this.layerDataRootContextMenuData[8].label != '加载中...') {
          const { icon, label, disabled } =
            this.layerDataFeaturesDataShow(data);
          this.layerDataRootContextMenuData[8].icon = icon;
          this.layerDataRootContextMenuData[8].label = label;
          this.layerDataRootContextMenuData[8].disabled = disabled;
        }

        contextMenuData = this.layerDataRootContextMenuData;
      } else {
        contextMenuData = this.layerDataClassContextMenuData;
      }
      // 设置选中标题
      // this.selectedDataId = this.getId(data);
      // 设置菜单数据
      this.$store.commit('common/setContextMenuData', contextMenuData);
      // 设置菜单可见状态
      this.$store.commit('common/setContextMenuVisible', true);
      // 设置菜单位置
      this.$store.commit('common/setContextMenuPosition', {
        top: e.pageY,
        left: e.pageX
      });
    },
    // 展开收起树状图
    toggleTree() {
      this.$store.commit('common/setEditTextId', '');
      // this.toggle = !this.toggle;
      this.toggle = false;
      let nodes = this.$refs.tree.root.childNodes;
      const expandedState = this.toggle;
      changeNodeExpandedState(nodes);
      // 改变节点展开状态
      function changeNodeExpandedState(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          node.expanded = expandedState;
          // 判断子节点长度
          if (node.childNodes.length > 0) {
            changeNodeExpandedState(node.childNodes);
          }
        }
      }
    },
    // 添加图层
    addLayerData() {
      this.$store.commit('layerData/setAddLayerDataDialogVisible', true);
    },
    // 搜索
    handleSearch() {
      this.$refs.tree.filter(this.keyword);
    },
    // 图层搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 关闭
    handleClose() {
      this.$router.push('/workbench/map');
    }
  },
  watch: {
    // 切换工作空间
    workspaceId() {
      this.getTreeData(this.workspaceId);
      this.$store.commit('layerData/quitLayerManager');
    },
    // 新增图层
    addLayerId(data) {
      if (data) {
        if (this.editTextId) return;
        this.currentNode.expanded = true;
        let newChild = {
          id: Math.floor(Math.random() * 10000 + 10000000) + '',
          name: ''
        };
        this.currentNode.data.child.push(newChild);
        this.$store.commit('common/setEditTextId', newChild.id);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layerManager {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &-btn {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &-content {
    flex: 1;
    overflow-y: auto;
  }
}

// 自定义节点
.custom-tree-node {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 20px 0 20px;
  color: #333;
  font-size: 18px;

  // 左侧toggle
  &-toggle {
    margin-right: 5px;
    &-icon {
      float: left;
      transition: transform 200ms ease-in-out;
      &.expanded {
        transform: rotate(90deg);
        color: #507cff;
      }
      &.box {
        display: inline-block;
        background-size: cover;
        width: 16px;
        height: 16px;
        background-image: url('~a/workbench/box.png');
      }
      &.box1 {
        display: inline-block;
        background-size: cover;
        width: 16px;
        height: 16px;
        background-image: url('~a/workbench/box1.png');
      }
    }
  }
  &-text {
    flex: 1;
    margin-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &-right {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
/deep/.el-tree-node__loading-icon {
  position: absolute;
  margin-top: 12px;
  margin-left: -20px;
}
.editText {
  /deep/.editText-ipt {
    &__inner {
      padding-right: 40px;
      height: 38px;
      line-height: 38px;
    }
    &-showWordLimit {
      right: 90px;
      font-size: 12px;
    }
    &-datasetCopy {
      margin-top: 43px;
    }
    .btn {
      margin: 0 10px;
    }
    .btn.cancel {
      position: absolute;
      right: 5px;
    }
  }
}
</style>
