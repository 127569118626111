<template>
  <!-- 服务发布 -->
  <el-dialog
    title="服务发布"
    :visible.sync="layerPublishingServiceDialogVisible"
    width="30vw"
    class="LayerPublishingServiceDialog"
  >
    <div class="LayerPublishingServiceDialog-title">
      <img src="~a/workbench/icon/icon-ImportServices.png" />
      从我的图层导入
    </div>
    <div
      class="LayerPublishingServiceDialog-title"
      style="color: #507cff; background: #ffffff"
    >
      <img src="~a/workbench/icon/icon-servicePublishing.png" />
      将该图层发布
    </div>
    <i class="icon-check" />
    <div class="LayerPublishingServiceDialog-table">
      <el-table
        :data="listData"
        highlight-current-row
        style="width: 100%"
        @current-change="handleChange"
      >
        <el-table-column align="center" label="选择">
          <template slot-scope="scope">
            <el-radio
              v-model="checked"
              :label="scope.$index"
              class="radio"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="classify"
          label="分类"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="type" label="服务类型" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: #507cff">{{ scope.row.type }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="LayerPublishingServiceDialog-position">
      <div class="LayerPublishingServiceDialog-position-title">发布位置：</div>
      <el-select
        v-model="value"
        clearable
        placeholder=" "
        class="LayerPublishingServiceDialog-position-select"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="form-btn-group">
      <Button
        class="form-btn cancel"
        @click.native="layerPublishingServiceDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click.native="handleConfirm"
        >确定</Button
      >
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/Button.vue';
export default {
  name: 'LayerPublishingServiceDialog',
  components: { Button },
  prop: {
    field: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      field: '',
      checked: null,
      checkedId: '',
      listData: [
        {
          id: 1,
          name: '1231212',
          classify: '水域',
          type: 'WMS'
        },
        {
          id: 2,
          name: '1231212',
          classify: '水域',
          type: 'WMS'
        },
        {
          id: 3,
          name: '1231212',
          classify: '水域',
          type: 'WMS'
        }
      ],
      options: [],
      value: ''
    };
  },
  computed: {
    layerPublishingServiceDialogVisible: {
      get() {
        return this.$store.state.layerData.layerPublishingServiceDialogVisible;
      },
      set(val) {
        this.$store.commit(
          'layerData/setLayerPublishingServiceDialogVisible',
          val
        );
      }
    }
  },
  created() {},
  methods: {
    // 提交
    handleConfirm() {
      if (!this.checkedId) {
        return this.$message({
          message: '请选择服务',
          type: 'warning'
        });
      }
      if (!this.value) {
        return this.$message({
          message: '请选择发布位置',
          type: 'warning'
        });
      }
      this.layerPublishingServiceDialogVisible = false;
    },
    // 单选服务
    handleChange(data) {
      this.checkedId = data.id;
    }
  }
};
</script>
<style lang="less" scoped>
.LayerPublishingServiceDialog {
  /deep/.el-dialog__body {
    padding: 20px 30px;
  }

  &-title {
    user-select: none;
    display: inline-block;
    margin-right: 15px;
    width: 173px;
    height: 32px;
    background: #507cff;
    border-radius: 4px;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #507cff;
    color: #ffffff;
  }
  &-table {
    margin-top: 10px;
    width: 513px;
    min-height: 177px;
    max-height: 477px;
    background: #ffffff;
    border: 1px solid #507cff;
    border-radius: 4px;
    overflow: hidden;
    /deep/.el-table {
      // 表格标题
      th.el-table__cell {
        font-size: 18px;
        background: #f8f9fd;
      }
      // 内容
      &__cell {
        line-height: 0;
        height: 44px;
        font-size: 16px;
        color: #333333;
        background-color: #ffffff;
        border: none;
        &.is-leaf {
          border: none;
        }
      }
      // 去除表格底部线条
      &::before {
        height: 0px;
      }
    }
  }
  &-position {
    margin-top: 20px;
    &-title {
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 19px;
    }
    /deep/&-select {
      margin-top: 10px;
      .el-input__inner {
        border: 1px solid #507cff;
      }
      .el-icon-arrow-up:before {
        color: #507cff;
        font-size: 20px;
      }
    }
  }
}

.radio /deep/ .el-radio__label {
  display: none;
}
</style>
