<template>
  <!--图层-基本属性 -->
  <div class="info">
    <InfoItem>
      <span slot="label"><span style="color: red">* </span>名称：</span>
      <el-input
        v-model="layerAttrDialogVisible.name"
        maxlength="20"
        show-word-limit
      />
    </InfoItem>
    <InfoItem label="类型：" :text="info.typeName" />
    <!-- <InfoItem label="上级：" :text="info.parentName" /> -->
    <!-- <InfoItem label="包含：" :text="info.contain" /> -->
    <InfoItem label="创建时间：" :text="layerAttrDialogVisible.createTime" />
    <InfoItem label="修改时间：" :text="layerAttrDialogVisible.updateTime" />
  </div>
</template>

<script>
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'LayerAttrBasic',
  components: {
    InfoItem
  },
  data() {
    return {
      info: {
        name: '一级图层',
        typeName: '图层',
        parentName: '无',
        contain: '15条数据',
        createTime: '202-07-08  14:52:00',
        updateTime: '202-07-08  14:52:00'
      }
    };
  },
  computed: {
    layerAttrDialogVisible: {
      get() {
        return this.$store.state.layerData.layerAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerAttrDialogVisible', val);
      }
    }
  }
};
</script>
