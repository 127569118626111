<template>
  <!-- 图层属性 -->
  <el-dialog
    class="dialog"
    title="属性"
    :visible.sync="layerAttrDialogVisible.status"
    width="19.47917vw"
  >
    <div class="tabs">
      <div class="tab-header">
        <el-radio-group v-model="tabActive">
          <el-radio-button label="1">基本属性</el-radio-button>
          <el-radio-button label="2">样式属性</el-radio-button>
        </el-radio-group>
      </div>
      <div class="tab-container">
        <component :is="tabComponent" />
      </div>
    </div>
    <div class="form-btn-group">
      <Button class="form-btn cancel" @click="layerAttrDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click="confirm">确定</Button>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import LayerAttrBasic from './LayerAttrBasic';
import LayerAttrStyle from './LayerAttrStyle';
export default {
  name: 'LayerAttrDialog',
  components: {
    Button
  },
  data() {
    return {
      tabActive: '2',
      tempData: {},
      form: {}
    };
  },
  methods: {
    // 提交表单
    confirm() {
      let data = this.layerAttrDialogVisible;
      let tempData = this.tempData;

      if (!data.name) return this.$message.warning('请输入名称');
      if (data.styleType == 1) {
        if (data.styleId) {
          if (
            data.styleId !== tempData.styleId ||
            data.name !== tempData.name ||
            data.styleType !== tempData.styleType
          ) {
            this.form = {
              id: data.id,
              name: data.name,
              styleType: data.styleType,
              styleId: data.styleId
            };
          } else {
            return (this.layerAttrDialogVisible = false);
          }
        } else {
          if (
            data.name !== tempData.name &&
            !tempData.styleId &&
            !tempData.pointStyleId
          ) {
            this.form = {
              id: data.id,
              name: data.name
            };
          } else {
            return this.$message('请选择样式文件');
          }
        }
      } else {
        if (data.pointStyleId && data.lineStyleId && data.polygonStyleId) {
          if (
            data.pointStyleId !== tempData.pointStyleId ||
            data.lineStyleId !== tempData.lineStyleId ||
            data.polygonStyleId !== tempData.polygonStyleId ||
            data.name !== tempData.name ||
            data.styleType !== tempData.styleType
          ) {
            this.form = {
              id: data.id,
              name: data.name,
              styleType: data.styleType,
              pointStyleId: data.pointStyleId,
              lineStyleId: data.lineStyleId,
              polygonStyleId: data.polygonStyleId
            };
          } else {
            return (this.layerAttrDialogVisible = false);
          }
        } else {
          if (
            data.name !== tempData.name &&
            !tempData.styleId &&
            !tempData.pointStyleId
          ) {
            this.form = {
              id: data.id,
              name: data.name
            };
          } else {
            return this.$message.warning('请选择点-线-多边形样式');
          }
        }
      }
      this.$service.layer.layerAttrSet(this.form).then(res => {
        if (res.data.status === 200) {
          this.$emit('refreshLayerData');
          this.$message.success('修改成功');
          this.layerAttrDialogVisible = false;
        }
      });
    }
  },
  computed: {
    // 切换tab时时切换显示的组件
    tabComponent() {
      let component = '';
      switch (this.tabActive) {
        case '1':
          component = LayerAttrBasic;
          break;
        case '2':
          component = LayerAttrStyle;
          break;
      }
      return component;
    },
    layerAttrDialogVisible: {
      get() {
        return this.$store.state.layerData.layerAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerAttrDialogVisible', val);
      }
    }
  },
  watch: {
    layerAttrDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.status) {
          this.tempData = JSON.parse(JSON.stringify(data));
          this.tabActive = '1';
          this.form = {};
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dialog /deep/ .el {
  &-dialog__body {
    padding: 20px 30px;
  }
  &-radio-group {
    display: flex;
  }
  &-radio-button {
    flex: 1;
    &__orig-radio {
      &:checked + .el-radio-button__inner {
        background-color: #507cff;
        border-color: #507cff;
        box-shadow: none;
        color: #fff;
      }
    }
    &__inner {
      width: 100%;
      padding: 0;
      height: 34px;
      line-height: 34px;
      border-color: #e5e5e5;
      color: #666;
      font-size: 16px;
    }
  }
}

.tab-container {
  height: 320px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-btn-group {
  justify-content: center !important;
  margin-top: 28px;
}
</style>
