import $bus from 'p/utils';
import pointMark from 'a/map/point-mark.png';
import { ip as urlIp } from 'p/urlSplice';
// 图层右键菜单
const layerDataRootContextMenuData = () => [
  {
    label: '新建',
    icon: 'add',
    fn(vm) {
      vm.$store.commit(
        'layerData/setAddLayerId',
        vm.$store.state.common.contextClickData
      );
    }
  },
  // {
  //   label: '移动',
  //   icon: 'move',
  //   // rightIcon: 'right',
  //   // childShow: false,
  //   disabled: true,
  //   // children: [
  //   //   {
  //   //     label: '上移一层',
  //   //     fn(vm) {
  //   //       const contextClickData = vm.$store.state.contextClickData;
  //   //       vm.$store.commit('layerData/setMoveDataDialogVisible', contextClickData.id);
  //   //     },
  //   //   },
  //   //   {
  //   //     label: '下移一层',
  //   //   },
  //   //   {
  //   //     label: '降级',
  //   //   },
  //   //   {
  //   //     label: '升级',
  //   //   }
  //   // ]
  // },
  {
    label: '删除',
    icon: 'del',
    fn(vm) {
      let data = vm.$store.state.common.contextClickData;
      let delSuccess = false;
      vm.$confirm(
        '删除“' + data.name + '”后数据不能恢复，是否确定要删除图层？',
        '删除图层',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'del'
        }
      ).then(() => {
        const params = {
          id: data.id
        };
        vm.$service.layer.layerDel(params).then(res => {
          if (res.data.status === 200) {
            vm.$message.success(res.data.msg);
            delSuccess = true;
            // 刷新图层列表数据
            $bus.$emit('refreshLayerData');
          }
          // 移除数据
          if (delSuccess) {
            vm.data[8].label = '隐藏数据';
            vm.data[8].fn && vm.data[8].fn(vm, vm.data[8]);
          }
        });
      });
    }
  },
  {
    label: '重命名',
    icon: 'rename',
    fn(vm) {
      if (vm.$store.state.layerData.addLayerId) return;
      vm.$store.commit(
        'common/setEditTextId',
        vm.$store.state.common.contextClickData.id
      );
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '添加数据',
    icon: 'addData',
    fn(vm) {
      const data = {
        status: true,
        name: vm.$store.state.common.contextClickData.name,
        id: vm.$store.state.common.contextClickData.id
      };
      vm.$store.commit('layerData/setLayerAddDataDialogVisible', data);
    }
  },
  // {
  //   label: '导出图层',
  //   icon: 'exportLayer',
  //   disabled: true
  // },
  {
    label: '更新服务',
    icon: 'update',
    disabled: true,
    fn(vm) {
      vm.$store.commit('layerData/setLayerUpdateServiceDialogVisible', true);
    }
  },
  {
    label: '服务发布',
    icon: 'release',
    disabled: true,
    fn(vm) {
      vm.$store.commit(
        'layerData/setLayerPublishingServiceDialogVisible',
        true
      );
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '展示数据',
    icon: 'showData',
    disabled: false,
    fn(vm, menuData) {
      if (menuData.label === '加载中...') return;
      const state = vm.$store.state;
      let contextClickData = state.common.contextClickData;
      let { layerManagerData } = state.layerData;
      // 图层id
      const { id } = contextClickData;
      // 图层子图层
      if (menuData.label === '展示数据') {
        // 修改加载数据为加载中...
        menuData.label = '加载中...';
        // 并禁用按钮
        menuData.disabled = true;

        // 从后台读取数据
        vm.$service.layer
          .layerDataLoad({
            id
          })
          .then(res => {
            const { status, data } = res.data;
            if (status === 200) {
              // 修改右键菜单文字
              menuData.label = '隐藏数据';
              menuData.icon = 'hideData';
              menuData.disabled = false;
              loadLayers(data);
            }
          });
      } else {
        // 移除数据
        // 从后台读取数据
        // vm.$service.layer
        //   .layerListTree({
        //     workspaceId: vm.$store.state.common.workspaceId
        //   })
        //   .then(res => {
        //     const { status, data } = res.data;
        //     if (status === 200) {
        // 修改隐藏数据为加载数据
        let data = state.layerData.layerTreeData;
        menuData.label = '展示数据';
        menuData.icon = 'showData';
        menuData.disabled = false;
        // 移除自身
        vm.$store.commit('layerData/setLayerManagerData', {
          key: id,
          val: {}
        });
        // 调整图层显示状态
        vm.$store.commit('layerData/setLayerDataFeaturesKey', {
          key: id,
          val: false
        });
        // 移除子项
        getChild(id, data, child => {
          removeLayers(child);
        });
        //   }
        // });
      }

      // 加载图层数据
      function loadLayers(layers) {
        for (let i = 0; i < layers.length; i++) {
          // 图层id，图层数据，图层子项
          const {
            id,
            parentId,
            layerData,
            child,
            iconStyle,
            lineStyle,
            polygonStyle
          } = layers[i];
          let data = {
            parentId
          };
          // 修改图层显示状态
          let layerDataFeaturesKey =
            vm.$store.state.layerData.layerDataFeaturesKey;
          vm.$set(layerDataFeaturesKey, id, true);
          for (const key in layerData) {
            const featureDatas = layerData[key];
            switch (key) {
              case 'points':
                {
                  const icon = iconStyle
                    ? urlIp.urlSplice(iconStyle.url)
                    : pointMark;
                  data['Point'] = featureDatas.map(item => {
                    return {
                      coordinates: [item.lng, item.lat, item.z],
                      icon,
                      show: true,
                      edit: false,
                      featureData: {
                        type: 'layerManagerFeature',
                        featureType: 'Point',
                        uuid: item.id,
                        dialogData: {
                          layerId: id,
                          classId: item.classId,
                          id: item.id
                        }
                      }
                    };
                  });
                }
                break;
              case 'lines':
                {
                  let segmentColors = lineStyle
                    ? lineStyle.fillColor.map(color => {
                        const hex = color.slice(0, 7);
                        const opacity = color.slice(7, 9)
                          ? (
                              (parseInt(color.slice(7, 9), 16) * (100 / 255)) /
                              100
                            ).toFixedNum(2)
                          : 1;
                        return [hex, opacity];
                      })
                    : [['#00A2E8', 1]];
                  data['Polyline'] = featureDatas.map(item => {
                    let coordinates = item.line.map(coord => [
                      coord.x,
                      coord.y,
                      coord.z
                    ]);
                    const featureData = {
                      coordinates,
                      segmentColors,
                      show: true,
                      edit: false,
                      change: e => {
                        const { type, index, point } = e;
                        let coordinate = [];
                        if (point) {
                          coordinate = [
                            point.longitude,
                            point.latitude,
                            point.altitude
                          ];
                        }
                        $bus.$emit('pointChange', { type, coordinate, index });
                        switch (type) {
                          case 'add':
                            featureData.coordinates.splice(
                              index,
                              0,
                              coordinate
                            );
                            break;
                          case 'remove':
                            featureData.coordinates.splice(index, 1);
                            break;
                          case 'set':
                            featureData.coordinates[index] = coordinate;
                            break;
                        }
                      },
                      featureData: {
                        type: 'layerManagerFeature',
                        featureType: 'Polyline',
                        uuid: item.id,
                        dialogData: {
                          layerId: id,
                          classId: item.classId,
                          id: item.id
                        }
                      }
                    };
                    return featureData;
                  });
                }
                break;
              case 'polygons':
                {
                  let fillColor = polygonStyle
                    ? polygonStyle.fillColor
                    : '#00A2E8B3';
                  const hex = fillColor.slice(0, 7);
                  const opacity = fillColor.slice(7, 9)
                    ? (
                        (parseInt(fillColor.slice(7, 9), 16) * (100 / 255)) /
                        100
                      ).toFixedNum(2)
                    : 1;
                  data['Polygon'] = featureDatas.map(item => {
                    let polygon = item.polygon;
                    // 删除最后一个首尾相连的点
                    polygon.pop();
                    let coordinates = polygon.map(coord => [
                      coord.x,
                      coord.y,
                      coord.z
                    ]);
                    const featureData = {
                      coordinates,
                      fillColor: [hex, opacity],
                      show: true,
                      edit: false,
                      change: e => {
                        const { type, index, point } = e;
                        let coordinate = [];
                        if (point) {
                          coordinate = [
                            point.longitude,
                            point.latitude,
                            point.altitude
                          ];
                        }
                        $bus.$emit('pointChange', { type, coordinate, index });
                        switch (type) {
                          case 'add':
                            featureData.coordinates.splice(
                              index,
                              0,
                              coordinate
                            );
                            break;
                          case 'remove':
                            featureData.coordinates.splice(index, 1);
                            break;
                          case 'set':
                            featureData.coordinates[index] = coordinate;
                            break;
                        }
                      },
                      featureData: {
                        type: 'layerManagerFeature',
                        featureType: 'Polygon',
                        uuid: item.id,
                        dialogData: {
                          layerId: id,
                          classId: item.classId,
                          id: item.id
                        }
                      }
                    };
                    return featureData;
                  });
                }

                break;
            }
          }

          vm.$set(layerManagerData, id, data);

          // 子项递归调用
          if (child.length) {
            loadLayers(child);
          }
        }
      }

      // 获取指定图层的子项
      function getChild(parentId, layers, cb) {
        for (let i = 0; i < layers.length; i++) {
          const { id, child } = layers[i];
          if (parentId === id) {
            cb(child);
          } else {
            getChild(parentId, child, cb);
          }
        }
      }

      // 移除图层
      function removeLayers(layers) {
        for (let i = 0; i < layers.length; i++) {
          // 图层id，图层数据，图层子项
          const { id: layersId, child } = layers[i];
          vm.$store.commit('layerData/setLayerManagerData', {
            key: layersId,
            val: {}
          });

          // 调整图层显示状态
          vm.$store.commit('layerData/setLayerDataFeaturesKey', {
            key: layersId,
            val: false
          });
          // 子项递归调用
          if (child.length) {
            removeLayers(child);
          }
        }
      }
    }
  },
  {
    label: '属性',
    icon: 'attr',
    fn(vm) {
      const contextClickData = vm.$store.state.common.contextClickData;
      // 获取属性数据
      vm.$service.layer
        .layerGetDetailAndStyle({ id: contextClickData.id, selectAllStyle: 1 })
        .then(res => {
          if (res.data.status === 200) {
            const params = res.data.data;
            params.status = true;
            vm.$store.commit('layerData/setLayerAttrDialogVisible', params);
          }
        });
    }
  }
];

// 数据类右键菜单
const layerDataClassContextMenuData = () => {
  let contextMenuData = [
    // {
    //   label: '复制',
    //   icon: 'copy',
    //   disabled: true
    // },
    // {
    //   label: '粘贴',
    //   icon: 'paste',
    //   disabled: true
    // },
    {
      label: '重命名',
      icon: 'rename',
      disabled: true,
      fn(vm) {
        vm.$store.commit('common/setEditTextId', '');
        const { dataId, layerId } = vm.$store.state.common.contextClickData;
        vm.$store.commit('common/setEditTextId', dataId + layerId);
      }
    },
    {
      label: '移除',
      icon: 'del',
      fn(vm) {
        let data = vm.$store.state.common.contextClickData;
        let layerManagerData = vm.$store.state.layerData.layerManagerData;

        const params = {
          layerId: data.layerId,
          dataId: data.dataId,
          type: data.type
        };
        vm.$service.layer.layerDataRemove(params).then(res => {
          if (res.data.status === 200) {
            vm.$message.success(res.data.msg);
            // 刷新图层列表数据
            $bus.$emit('refreshPartialLayer');
            // 查看是否包含数据 有则移除
            if (layerManagerData[data.layerId]) {
              delShowData(layerManagerData[data.layerId]);
            }
          }
        });

        // 移除地图显示数据
        function delShowData(layer) {
          let featureKey = {
            1: 'Point',
            3: 'Polyline',
            5: 'Polygon'
          };
          layer[featureKey[data.subType]].map(item => {
            if (item.featureData.dialogData.classId == data.dataId) {
              item.show = false;
            }
          });
        }
      }
    },
    {
      type: 'splitLine'
    },
    // {
    //   label: '展示数据',
    //   icon: 'showData',
    //   disabled: false,
    //   fn(vm, menuData) {
    //     // 正在加载
    //     if (menuData.label === '加载中...') return;
    //     const state = vm.$store.state;
    //     let contextClickData = state.common.contextClickData;
    //     let { layerDataNeedUpdate, layerManagerData } = state.layerData;
    //     // 图层id
    //     const { id } = contextClickData;
    //     if (menuData.label === '展示数据') {
    //       // 修改加载数据为加载中...
    //       menuData.label = '加载中...';
    //       // 并禁用按钮
    //       menuData.disabled = true;

    //       // 加载对应数据
    //       // 判断是否存在数据
    //       let layerDataObj = layerManagerData[id];
    //       // 判断数据是否更新了，更新了就去请求最新数据
    //       if (layerDataNeedUpdate.includes(id)) {
    //         layerDataObj = '';
    //       }
    //       if (layerDataObj) {
    //         // 存在这从本地读取
    //         for (const key in layerDataObj) {
    //           if (Object.hasOwnProperty.call(layerDataObj, key)) {
    //             let featuresData = layerDataObj[key];
    //             featuresData.forEach(featureData => {
    //               featureData.show = true;
    //             });
    //           }
    //         }
    //         menuData.label = '隐藏数据';
    //         menuData.icon = 'hideData';
    //         menuData.disabled = false;
    //       } else {
    //         // 不存在则从后台读取
    //         vm.$service.layer
    //           .layerDataLoad({
    //             id
    //           })
    //           .then(res => {
    //             const { status, data } = res;
    //             if (status === 200) {
    //               // 修改右键菜单文字
    //               menuData.label = '隐藏数据';
    //               menuData.icon = 'hideData';
    //               menuData.disabled = false;
    //               loadLayers(data);
    //             }
    //           });
    //       }
    //     } else {
    //       // 修改隐藏数据为加载数据
    //       menuData.label = '展示数据';
    //       menuData.icon = 'showData';
    //       menuData.disabled = false;
    //       // 隐藏数据
    //       let layerManagerDataObj = layerManagerData[id];
    //       if (layerManagerDataObj) {
    //         for (const key in layerManagerDataObj) {
    //           if (Object.hasOwnProperty.call(layerManagerDataObj, key)) {
    //             const featuresData = layerManagerDataObj[key];
    //             // 隐藏数据
    //             featuresData.forEach(featureData => {
    //               featureData.show = false;
    //             });
    //           }
    //         }
    //       }
    //     }

    //     // 加载图层数据
    //     function loadLayers(layers) {
    //       for (let i = 0; i < layers.length; i++) {
    //         // 图层id，图层数据，图层子项
    //         const { id, layerData, child } = layers[i];
    //         let layerManagerData = {};
    //         for (const key in layerData) {
    //           const featureDatas = layerData[key];
    //           switch (key) {
    //             case 'points':
    //               layerManagerData['Point'] = featureDatas.map(item => {
    //                 return {
    //                   coordinates: [item.lng, item.lat, item.z],
    //                   icon: pointMark,
    //                   show: true,
    //                   featureData: {
    //                     type: 'layerManagerFeature',
    //                     featureType: 'Point',
    //                     uuid: item.id,
    //                     dialogData: {
    //                       layerId: id,
    //                       classId: item.classId,
    //                       id: item.id
    //                     }
    //                   }
    //                 };
    //               });
    //               break;
    //             case 'lines':
    //               layerManagerData['Polyline'] = featureDatas.map(item => {
    //                 let coordinates = item.line.map(coord => [
    //                   coord.x,
    //                   coord.y,
    //                   coord.z
    //                 ]);
    //                 return {
    //                   coordinates,
    //                   segmentColors: [['#00A2E8', 1]],
    //                   show: true,
    //                   featureData: {
    //                     type: 'layerManagerFeature',
    //                     featureType: 'Polyline',
    //                     uuid: item.id,
    //                     dialogData: {
    //                       layerId: id,
    //                       classId: item.classId,
    //                       id: item.id
    //                     }
    //                   }
    //                 };
    //               });
    //               break;
    //             case 'polygons':
    //               layerManagerData['Polygon'] = featureDatas.map(item => {
    //                 let coordinates = item.polygon.map(coord => [
    //                   coord.x,
    //                   coord.y,
    //                   coord.z
    //                 ]);
    //                 let fillColor = item.polygonStyle
    //                   ? item.polygonStyle.fillColor
    //                   : '#00A2E8B3';
    //                 const hex = fillColor.slice(0, 7);
    //                 const opacity = fillColor.slice(7, 9)
    //                   ? (
    //                       (parseInt(fillColor.slice(7, 9), 16) * (100 / 255)) /
    //                       100
    //                     ).toFixedNum(2)
    //                   : 1;
    //                 return {
    //                   coordinates,
    //                   fillColor: [hex, opacity],
    //                   show: true,
    //                   featureData: {
    //                     type: 'layerManagerFeature',
    //                     featureType: 'Polygon',
    //                     uuid: item.id,
    //                     dialogData: {
    //                       layerId: id,
    //                       classId: item.classId,
    //                       id: item.id
    //                     }
    //                   }
    //                 };
    //               });
    //               break;
    //           }
    //         }
    //         vm.$store.commit('layerData/setLayerManagerData', {
    //           key: id,
    //           val: layerManagerData
    //         });
    //         // 子项递归调用
    //         if (child.length) {
    //           loadLayers(child);
    //         }
    //       }
    //     }
    //   }
    // },
    {
      label: '打开属性表',
      icon: 'attrSheet',
      fn(vm) {
        const { layerId, dataId, name, subType } =
          vm.$store.state.common.contextClickData;
        const contextClickData = {
          id: dataId,
          name: name,
          dataType: subType,
          layerId: layerId
        };
        vm.$store.commit('data/setDataclassAttrSheetPanelVisible', true);

        let data = Object.assign(
          {
            propertySheetType: 'data',
            status: true
          },
          contextClickData
        );
        vm.$store.commit('data/setDataclassAttrSheetPanelVisible', data);
      }
    },
    {
      label: '属性',
      icon: 'attr',
      fn(vm) {
        const { dataId, name, type, subType } =
          vm.$store.state.common.contextClickData;
        let typeName = '';
        let subTypeName = '';
        switch (type) {
          case 1:
            typeName = '要素数据';
            switch (subType) {
              case 1:
                subTypeName = '点';
                break;
              case 3:
                subTypeName = '线';
                break;
              case 5:
                subTypeName = '多边形';
                break;
              default:
                break;
            }
            break;

          default:
            break;
        }
        const data = {
          status: true,
          id: dataId,
          name: name,
          typeName: typeName + ' - ' + subTypeName
        };
        vm.$store.commit('layerData/setLayerClassAttrDialogVisible', data);
      }
    }
  ];
  return contextMenuData;
};

export { layerDataRootContextMenuData, layerDataClassContextMenuData };
