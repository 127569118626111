<template>
  <!--数据类属性 -->
  <el-dialog
    title="属性"
    :visible="layerClassAttrDialogVisible.status"
    width="19.47917vw"
    @close="layerClassAttrDialogVisible = false"
  >
    <div class="info">
      <InfoItem label="名称：" :text="layerClassAttrDialogVisible.name">
        <!-- <el-input
          v-model.trim="layerClassAttrDialogVisible.name"
          maxlength="20"
          show-word-limit
        /> -->
      </InfoItem>
      <InfoItem label="类型：" :text="layerClassAttrDialogVisible.typeName" />
      <!-- <InfoItem label="上级：" :text="info.typeName" /> -->
    </div>
    <div class="form-btn-group">
      <!-- <Button
        class="form-btn cancel"
        @click="layerClassAttrDialogVisible = false"
        >取消</Button
      > -->
      <!-- <Button
        class="form-btn confirm"
        @click="layerClassAttrDialogVisible = false"
        >确定</Button
      > -->
    </div>
  </el-dialog>
</template>

<script>
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'LayerClassAttrBasic',
  components: {
    InfoItem
  },
  data() {
    return {};
  },
  computed: {
    layerClassAttrDialogVisible: {
      get() {
        return this.$store.state.layerData.layerClassAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('layerData/setLayerClassAttrDialogVisible', val);
      }
    }
  },
  methods: {
    // 确定
    handleConfirm(data) {
      if (!data.name) {
        return this.$message('名称不能为空');
      }
      const params = {
        id: data.id,
        name: data.name
      };
      this.$service.layer.layerModifyName(params).then(res => {
        if (res.data.status == 200) {
          this.$message.success(res.data.msg);
          this.$emit('partialRefresh');
          this.layerClassAttrDialogVisible = false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.form-btn-group {
  margin-top: 30px;
}
</style>
